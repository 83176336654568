import React from 'react'

import Layout from '../components/layout';
import SEO from '../components/seo';

const Professionals = () => {
    return (
        <Layout>
            <SEO title="Professionals" />
            hi from professionals
        </Layout>
    )
}

export default Professionals
